import React, { useEffect, useState } from "react";
import ApiAdmin from "../../../api-admin";
import SingleItemAccordion from "../SingleItemAccordion";
import { Modal } from "react-bootstrap";
import { useTranslate } from "../TranslationProvider";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "../Spinner";
import {RiComputerLine, RiWindow2Fill, RiErrorWarningLine} from "react-icons/ri"
import {BsCalendarDate} from "react-icons/bs"
import { stringToHexColor } from "../../../utils/common";

const USER_AGENT_MAX_LENGTH = 11;
const MAX_LOGIN_LOGS_LIMIT = 50;

const LoginLogs = ({ userId }) => {
  const [fetchData, setFetchData] = useState({
    userLoginLogs: null,
    isFetching: false,
    error: null,
  });
  const [unique, setUnique] = useState({ machineIds:null, userAgents:null });
  const [logDetails, setLogDetails] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [isShowingAllLogs, setIsShowingAllLogs] = useState(false);
  const translate = useTranslate();

  useEffect(() => {
    fetchUserLoginLogs(userId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUnique = (logs, propertyName) => {
    const allResults = logs?.map((log) => log[propertyName]);
    const uniqueResults = new Set(allResults);
  
    return uniqueResults.size;
  };

  const fetchUserLoginLogs = (userId) => {
    setFetchData({
      userLoginLogs: null,
      isFetching: true,
      error: null,
    });

    ApiAdmin.getUserLoginLogs(userId).then((response) => {
      if (response.error) {
        setFetchData({
          userLoginLogs: null,
          isFetching: false,
          error: response.error,
        });
      } else {
        for (const loginLog of response.loginLogs) {
          loginLog.userAgentHex = stringToHexColor(loginLog.userAgent);
        }
        const uniqueMachineIds = getUnique(response.loginLogs ,"machineId")
        const uniqueUserAgents = getUnique(response.loginLogs, "userAgent")

        setFetchData({
          userLoginLogs: response.loginLogs,
          isFetching: false,
          error: null,
        });
        setUnique({
          machineIds:uniqueMachineIds,
          userAgents: uniqueUserAgents
        })
      }
    });
  };

  const renderModal = () => {
    return (
      <Modal
        className="modal-wrap"
        onEscapeKeyDown={() => setIsModal(false)}
        show={isModal}
      >
        <Modal.Body>
          {Object.entries(logDetails).map((entry) => {
            const [key, value] = entry;

            if (typeof value === "object") {
              return;
            }

            return (
              <p key={key}>
                <strong>{key}: </strong> {value}
              </p>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setIsModal(false)}>
            {translate("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const accordionContent = (
    <div>
      {renderModal()}
      <div className="admin-login-logs padded-small">
        <div className="logs-overview">
          <div>
            <h5>
              {translate("total_logins") +
                ": " +
                fetchData.userLoginLogs?.length}
            </h5>
          </div>
          <div>
            <h5>{translate("unique_machines") + ": " + unique.machineIds}</h5>
          </div>
          <div>
            <h5>
              {translate("unique_user_agents") + ": " + unique.userAgents}
            </h5>
          </div>
        </div>
        <hr />
        <div className="logs-container">
          {fetchData.userLoginLogs
            ?.slice(
              0,
              isShowingAllLogs
                ? fetchData.userLoginLogs.length
                : MAX_LOGIN_LOGS_LIMIT
            )
            .map((log) => (
              <div
                key={log.id}
                className="login-log-item touchable"
                onClick={() => {
                  setLogDetails(log);
                  setIsModal(true);
                }}
              >
                <div>
                  <BsCalendarDate />
                  &nbsp;
                  {log.localDate}
                </div>
                <div>
                  <RiComputerLine />
                  &nbsp;
                  {log.machineId}
                </div>
                <div>
                  <RiWindow2Fill />
                  &nbsp;
                  {log.userAgent.slice(0, USER_AGENT_MAX_LENGTH)}&nbsp;
                  <span style={{ color: log.userAgentHex }}>&#11044;</span>
                </div>
                {log.error && (
                  <div className="alert alert-danger m-0 p-0 px-1" role="alert">
                    <RiErrorWarningLine />
                    &nbsp;
                    {log.error}
                  </div>
                )}
              </div>
            ))}
        </div>
        {!isShowingAllLogs &&
          fetchData.userLoginLogs?.length > MAX_LOGIN_LOGS_LIMIT && (
            <Button onClick={() => setIsShowingAllLogs(true)}>
              {translate("show_all")}
            </Button>
          )}
      </div>
    </div>
  );

  const renderFetching = () => {
    return (
      <SingleItemAccordion
        title={
          <div>
            <Spinner /> {translate("login_logs")}
          </div>
        }
        content=""
      />
    );
  };

  const renderContent = () => {
    return (
      <SingleItemAccordion
        title={translate("login_logs")}
        content={
          fetchData.userLoginLogs?.length === 0 ? (
            <p>{translate("not_available")}</p>
          ) : (
            accordionContent
          )
        }
      />
    );
  };

  return (
    <div>
      {fetchData.isFetching && renderFetching()}
      {fetchData.error && <Alert>{fetchData.error}</Alert>}
      {!fetchData.isFetching && !fetchData.error && renderContent()}
    </div>
  );
};

export default LoginLogs;
