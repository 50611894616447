import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Alert,
  Button,
  Form,
  InputGroup,
  Modal,
  Pagination,
} from "react-bootstrap";
import { FiChevronRight } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import ApiAdmin from "../../../api-admin";
import DateFormat from "../../../utils/date";
import Spinner from "../Spinner";
import { useTranslate } from "../TranslationProvider";

const EventLogs = ({ targetId, targetType, searchEnabled }) => {
  const navigate = useNavigate();
  const t = useTranslate();
  const [selectedLogDetails, setSelectedLogDetails] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [isShowingLogDetails, setIsShowingLogDetails] = useState(false);
  const [offsetArr, setOffsetArr] = useState([0, 1, 2]);
  const [eventLogsData, setEventLogsData] = useState({
    eventLogs: null,
    isFetching: false,
    error: null,
    count: null,
    pages: null,
  });

  useLayoutEffect(() => {});

  const fetchEventLogs = (searchTerm) => {
    setEventLogsData({ ...eventLogsData, isFetching: true, error: null });
    ApiAdmin.getEventLogs(
      activePage - 1,
      searchTerm || "",
      targetId,
      targetType
    ).then((response) => {
      if (response.error) {
        setEventLogsData({
          ...eventLogsData,
          isFetching: false,
          error: response.error,
        });
      } else {
        setEventLogsData({
          eventLogs: response.eventLogs,
          count: response.count,
          pages: response.pages,
          isFetching: false,
          error: null,
        });
      }
    });
  };

  useEffect(() => {
    fetchEventLogs();
    if (activePage === 1) {
      return setOffsetArr([0, 1, 2]);
    }
    if (activePage === eventLogsData.pages) {
      return setOffsetArr([-2, -1, 0]);
    }

    setOffsetArr([-1, 0, 1]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  const navigateToTargetId = (targetType, id) => {
    switch (targetType) {
      case "user":
        navigate("/user/" + id);
        break;
      case "school":
        navigate("/school-settings/" + id);
        break;
      default:
    }
  };

  const renderTopPagination = () => {
    if (!eventLogsData.eventLogs || eventLogsData.eventLogs.length === 0) {
      return <Alert>{t("no_search_results")}</Alert>;
    }

    return (
      <Pagination>
        <Pagination.Prev
          onClick={() => {
            if (activePage !== 1) {
              setActivePage((curr) => curr - 1);
            }
          }}
        />
        <Pagination.Item onClick={() => setActivePage(1)}>{1}</Pagination.Item>
        <Pagination.Ellipsis />
        {offsetArr.map((offset) => {
          if (
            activePage + offset > eventLogsData.pages ||
            activePage + offset < 1
          ) {
            return;
          }

          return (
            <Pagination.Item
              key={offset}
              active={activePage === activePage + offset}
              onClick={() => setActivePage(activePage + offset)}
            >
              {activePage + offset}
            </Pagination.Item>
          );
        })}

        <Pagination.Ellipsis />
        <Pagination.Item onClick={() => setActivePage(eventLogsData.pages)}>
          {eventLogsData.pages}
        </Pagination.Item>
        <Pagination.Next
          onClick={() => {
            if (activePage !== eventLogsData.pages) {
              setActivePage((curr) => curr + 1);
            }
          }}
        />
      </Pagination>
    );
  };

  const renderBottomPagination = () => {
    if (!eventLogsData.eventLogs || eventLogsData.count === 0) {
      return;
    }

    return (
      <Pagination>
        <Pagination.Prev
          onClick={() => {
            if (activePage !== 1) {
              setActivePage((curr) => curr - 1);
            }
          }}
        />
        <Pagination.Next
          onClick={() => {
            if (activePage !== eventLogsData.pages) {
              setActivePage((curr) => curr + 1);
            }
          }}
        />
        {activePage === eventLogsData.pages && (
          <span className="my-auto mx-2">{t("last_page")}</span>
        )}
      </Pagination>
    );
  };
  const renderLogDetailsModal = () => {
    if (selectedLogDetails) {
      return (
        <Modal
          className="event-log-modal"
          show={isShowingLogDetails}
          onHide={() => setIsShowingLogDetails(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("log_details")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              {Object.entries(selectedLogDetails).map((entry) => {
                const [key, value] = entry;

                if (typeof value === "object") {
                  return (
                    <li key={key}>
                      <strong>{key}: </strong>&nbsp;
                      {JSON.stringify(value)}
                    </li>
                  );
                }

                return (
                  <li key={key}>
                    <strong>{key}: </strong>&nbsp;
                    {key === "targetId" ? (
                      <u
                        onClick={() =>
                          navigateToTargetId(
                            selectedLogDetails.targetType,
                            value
                          )
                        }
                        className="touchable"
                      >
                        {value}
                      </u>
                    ) : (
                      value
                    )}
                  </li>
                );
              })}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setIsShowingLogDetails(false)}>{t("close")}</Button>
          </Modal.Footer>
        </Modal>
      );
    }
  };

  const renderSearch = () => {
    if (searchEnabled) {
      return (
        <div id="event-log-search-container">
          <EventSearch
            setActivePage={setActivePage}
            fetchEventLogs={fetchEventLogs}
          />
        </div>
      );
    }
  };

  return (
    <div>
      {renderSearch()}
      <div className="event-logs-results white-box">
        {renderLogDetailsModal()}
        <div className="d-flex gap-3">
          {renderTopPagination()}
          {eventLogsData.isFetching && <Spinner />}
        </div>
        <hr />
        <div className="custom-table full-width">
          <div className="custom-table-header">
            <div className="ct-column-name">{t("user")}</div>
            <div className="ct-column-name">{t("event")}</div>
            <div className="ct-column-name">{t("target")}</div>
            <div className="ct-column-name">{t("date")}</div>
            <div className="ct-column-name"></div>
          </div>
          <div className="ct-table-body">
            {eventLogsData.eventLogs?.map((log) => {
              return (
                <div
                  key={log.id}
                  className="ct-table-row touchable"
                  onClick={() => {
                    setSelectedLogDetails(log);
                    setIsShowingLogDetails(true);
                  }}
                >
                  <div className="ct-table-body-cell">
                    <strong className="ct-mobile-only">{t("user")}:</strong>
                    &nbsp;
                    {log.triggeringUserId}
                  </div>
                  <div className="ct-table-body-cell">
                    <strong className="ct-mobile-only">{t("event")}:</strong>
                    &nbsp;
                    {log.eventType}
                  </div>
                  <div className="ct-table-body-cell">
                    <strong className="ct-mobile-only">{t("target")}:</strong>
                    &nbsp;
                    {log.targetId} ({log.targetType})
                  </div>
                  <div className="ct-table-body-cell">
                    {DateFormat.dateTime(log.createdAt)}
                  </div>
                  <div className="ct-table-body-cell">
                    <span className="ct-desktop-only">
                      <FiChevronRight />
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {renderBottomPagination()}
      </div>
    </div>
  );
};

const EventSearch = ({ fetchEventLogs, setActivePage }) => {
  const t = useTranslate();
  const [searchTerm, setSearchTerm] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    setActivePage(1);
    fetchEventLogs(searchTerm);
  };
  return (
    <Form onSubmit={submitHandler} className="fade-in">
      <Form.Group>
        <InputGroup>
          <InputGroup.Text>
            <IoSearch />
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder={t("search")+"..."}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <InputGroup>
            <Button onClick={submitHandler}>{t("search")}</Button>
          </InputGroup>
        </InputGroup>
      </Form.Group>
    </Form>
  );
};

export default EventLogs;
