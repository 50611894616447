import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Spinner from "../Spinner"
import api from "../../../api-admin"
import Translated from "../Translated";
import withTranslate from "../withTranslate";
import Logger from "../../../utils/logger";
import EmailUtil from "../../../utils/EmailUtil";

const AdminSearchEmailEvents = ({ translate, propsEmail = null }) => {
  const { email } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [searchTerm, setSearchTerm] = useState(propsEmail || email || "");
  const [emailEvents, setEmailEvents] = useState(null);
  const [eventsRange, setEventsRange] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (searchTerm) {
      fetchEmailEvents();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEmailEvents = () => {
    setIsFetching(true);
    api
      .searchEmailEvents(searchTerm)
      .then((res) => {
        setIsFetching(false);
        if (res.error) {
          setError(res.error);
          setEmailEvents(null);
          setEventsRange(null);
        } else {
          setError(res.events.length === 0 ? 'no_search_results' : null);
          setEmailEvents(res.events);
          setEventsRange(res.range);
        }
      })
      .catch((err) => {
        Logger.error(err);
        setError("unknown-error");
        setEmailEvents(null);
        setEventsRange(null);
        setIsFetching(false);
      });
  };

  const inputChangeHandler = (e) => {
    setSearchTerm(e.target.value.trim());
  };

  const formSubmit = (e) => {
    e.preventDefault();

    if (!EmailUtil.isEmail(searchTerm)) {
      setError("invalid-input");
      setEventsRange(null);
      return;
    }

    fetchEmailEvents();
  };

  const renderResultsContent = () => {
    if (isFetching) {
      return (
        <div className="centered large-top-margin">
          <Spinner />
        </div>
      );
    }

    if (error) {
      return (
          <div className="large-top-margin">
            <Alert>
              <Translated translationKey={error}/>
            </Alert>
            <div>
              {eventsRange && (eventsRange.startDate + ' - ' + eventsRange.endDate)}
            </div>
          </div>
      );
    }

    if (emailEvents) {
      return (
        <div className="admin-ee-results-container blue-box">
          <h4>
            <Translated translationKey="email_to" />: {emailEvents[0].email}
          </h4>
          <div>
            ({eventsRange && (eventsRange.startDate + ' - ' + eventsRange.endDate)})
          </div>

          {emailEvents.map((email) => (
            <div key={email.date} className="ee-result">
              <strong>
                <p>{email.subject}</p>
              </strong>
              <p>{`${translate("email_from")}: ${email.from}`}</p>

              {email.reason && (
                <div className="ee-reason">
                  <p>Reason: {email.reason}</p>
                  <span className={`ee_status_${email.event}`}>
                    <p>Status: {email.event}</p>
                  </span>
                </div>
              )}

              {!email.reason && (
                <span className={`ee_status_${email.event}`}>
                  <p>Status: {email.event}</p>
                </span>
              )}

              <p className="ee-date">{email.localDate || '-'}</p>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div>
      {!propsEmail && (
        <div className="admin-ee-search blue-box">
          <h2>{translate("search_for_email_events")}:</h2>
          <form onSubmit={formSubmit}>
            <input
              value={searchTerm || ""}
              onChange={inputChangeHandler}
              type="text"
              id="email"
              placeholder={translate("email")}
            />

            <Button
              disabled={isFetching}
              id="search-button"
              type="submit"
              className="btn btn-primary"
            >
              <Translated translationKey="search_do" />
            </Button>
          </form>
        </div>
      )}

      {renderResultsContent()}
    </div>
  );
};

export default withTranslate(AdminSearchEmailEvents);
