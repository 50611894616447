import React, {useState,useEffect} from "react"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from './../../feature/Spinner';
import ApiAdmin from "../../../api-admin";
import { useTranslate } from "../TranslationProvider";

const UserComments = ({ userId }) => {
  const [newComment, setNewComment] = useState("");
  const [fetchData, setFetchData] = useState({
    userComments: null,
    isFetching: false,
    error: null,
  });
  const translate = useTranslate();

  const fetchUserComments = () => {
    setFetchData({ userComments: null, isFetching: true, error: null });

    ApiAdmin.getUserComments(userId).then((response) => {
      if (response.error) {
        setFetchData({
          userComments: null,
          isFetching: false,
          error: response.error,
        });
      } else {
        setFetchData({
          userComments: response,
          isFetching: false,
          error: null,
        });
      }
    });
  };

  useEffect(() => {
    fetchUserComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const addComment = (comment) => {
    setFetchData({
      ...fetchData,
      isFetching: true,
      error: null,
    });
    ApiAdmin.addUserComment(userId, comment).then((response) => {
      if (response.error) {
        setFetchData({
          userComments: null,
          isFetching: false,
          error: response.error,
        });
      } else {
        setFetchData({
          userComments: response,
          isFetching: false,
          error: null,
        });
      }
    });
  };

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!newComment || newComment.trim().length === 0) {
      return setFetchData({
        ...fetchData,
        error: "invalid-input",
      });
    }

    addComment(newComment);
    setNewComment("");
  };

 const renderComments = () => {
   if (fetchData.error) {
     return <Alert>{translate(fetchData.error)}</Alert>;
   }

  const renderUsernameFromComment = (comment) => {
    if (!comment.user.userName || comment.user.userName.length === 0) {
      return translate("not_available");
    }
    return comment.user.userName;
  };

   const comments = fetchData.userComments;
   if (!comments || comments.length === 0) {
     return null;
   }

   const commentElems = [];
   comments.forEach((comment) => {
     commentElems.push(
       <p key={comment.id}>
         <span className="semi-transparent">
           {renderUsernameFromComment(comment)}
           , {comment.localDate}
         </span>
         <br />
         {comment.comment}
       </p>
     );
   });
   return commentElems;
 };

  return (
    <div className="comment-list">
      <div id="user-search-container">
        <Form onSubmit={onSubmit} className="fade-in">
          <Form.Group className="mb-3">
            <InputGroup>
              <Form.Control
                type="text"
                name="newComment"
                placeholder={translate("add_comment")}
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                onFocus={() => setFetchData({ ...fetchData, error: null })}
              />
              <InputGroup>
                <Button
                  className="top-margin-small"
                  id="add-comment-button"
                  onClick={onSubmit}
                >
                  {translate("add_comment")}
                </Button>
              </InputGroup>
            </InputGroup>
          </Form.Group>
        </Form>
      </div>
      <br />
      {fetchData.isFetching && <Spinner />}
      {renderComments()}
    </div>
  );
};

export default UserComments
