import React, { useCallback, useEffect, useState } from "react";
import SingleItemAccordion from "../SingleItemAccordion";
import ApiAdmin from "../../../api-admin";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useTranslate } from "../TranslationProvider";
import Spinner from "../Spinner";
import Translated from "../Translated";
import Alert from "react-bootstrap/Alert";

const AdminListWebshopOrders = ({ userEmail }) => {
  const [fetchData, setFetchData] = useState({
    webshopOrders: null,
    isFetching: false,
    error: null,
  });
  const [changeOrderStatusData, setChangeOrderStatusData] = useState({
    isFetching: false,
    error: null,
  });
  const [homepageUrl, setHomepageUrl] = useState(null);
  const [orderDetails, setOrderDetails] = useState({});
  const [isModal, setIsModal] = useState(false);
  const translate = useTranslate();

  const fetchWebshopOrders = (userEmail) => {
    setFetchData({...fetchData,
      isFetching: true,
      error: null,
    });
    ApiAdmin.searchWebshopOrdersByEmail(userEmail).then((response) => {
      if (response.error) {
        setFetchData({
          webshopOrders: null,
          isFetching: false,
          error: response.error,
        });
      } else {
        setFetchData({
          webshopOrders: response.orders,
          isFetching: false,
          error: null,
        });
        setHomepageUrl(response.homePageUrl);
      }
    });
  }

  useEffect(() => {
    fetchWebshopOrders(userEmail);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeOrderStatusToOk = (orderId) => {
    setChangeOrderStatusData({
      isFetching:true,
      error:false
    })
    ApiAdmin.changeOrderStatusToOk(orderId).then(response => {
      if (response.error) {
        setChangeOrderStatusData({
          isFetching: false,
          error: response.error,
        });
      } else {
        setChangeOrderStatusData({
          isFetching: false,
          error: null,
        });
        setOrderDetails(response.updatedOrder);
      }
      fetchWebshopOrders(userEmail);
    })
  }

  const renderContent = () => {
    return (
      <SingleItemAccordion
        title={
          fetchData.isFetching ? (
            <div>
              <Spinner /> {translate("webshop_orders")}
            </div>
          ) : (
            translate("webshop_orders")
          )
        }
        content={
          fetchData.webshopOrders?.length === 0 ? (
            <Translated translationKey="not_available" />
          ) : (
            accordionContent
          )
        }
      />
    );
  };

  const renderModal = () => {
    return (
      <Modal
        className="modal-wrap"
        onEscapeKeyDown={() => {
          setChangeOrderStatusData({
            isFetching: false,
            error: null,
          })
          setIsModal(false)}}
        show={isModal}
      >
        <Modal.Header>
          <Button
            href={`${homepageUrl}/order/${orderDetails.id}/${orderDetails.token}`}
          >
            {translate("order_info_page")}
          </Button>
        </Modal.Header>
        <Modal.Body>
          {Object.entries(orderDetails).map((entry) => {
            const [key, value] = entry;

            if(key === "status") {
              return (
                <div key={key}>
                  <p>
                    <strong>{key}: </strong>
                    {value}&nbsp;
                    {value !== "ok" && (
                      <Button
                        onClick={() => changeOrderStatusToOk(orderDetails.id)}
                      >
                        {translate("accept_order")}
                        {changeOrderStatusData.isFetching ? <Spinner /> : null}
                      </Button>
                    )}
                  </p>
                  {changeOrderStatusData.error ? (
                    <Alert>{translate(changeOrderStatusData.error)}</Alert>
                  ) : null}
                </div>
              );
            }

            if (typeof value === "object") {
              return (
                <p key={key}>
                  <strong>{key}: </strong>{JSON.stringify(value)}
                </p>
              );
            }

            return (
              <p key={key}>
                <strong>{key}: </strong>{value}
              </p>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setIsModal(false)}>
            {translate("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const addPunctuationToProducts = (order) => {
    const productsArr = order.orderedProducts.map((product) => {
      return product.productData.category.toUpperCase();
    });

    return productsArr.join(", ");
  };

  const constructActionsString = (actions) => {
    const parsedActions = JSON.parse(actions).actions;

    const actionStrings = parsedActions.map((action, i) => {
      const periodDays = action.periodDays;
      const category = action.categories[0];

      return (
        <p key={i}>
          <strong>{category.toUpperCase()}</strong>: {periodDays}{" "}
          {translate("days")}
        </p>
      );
    });

    return <div>{actionStrings.map((actionString) => actionString)}</div>;
  };

  const getStatusClassName = (statusCode) => {
    const possibleStatuses = ["new", "ok", "fail"];
    if (possibleStatuses.some((status) => status.startsWith(statusCode))) {
      return `user-order-item-${statusCode}`;
    }
    return "user-order-item-caution";
  };

  const accordionContent = (
      <div className="user-orders-container padded-small">
        {fetchData.webshopOrders?.map((order) => {
          const productsString = addPunctuationToProducts(order);
          const statusClassName = getStatusClassName(order.status);

          return (
            <div
              onClick={() => {
                setOrderDetails(order);
                setIsModal(true);
              }}
              key={order.id}
              className={`user-order-item padded-small ${statusClassName}`}
            >
              <strong>
                {translate("license_class")}: {productsString}
              </strong>
              <strong>
                {translate("order_id")}: {order.id}
              </strong>
              <br />
              <p>{order.localCreationDate}</p>
              <p>
                {translate("order_total_price", {
                  totalPrice: order.totalCents / 100,
                })}
              </p>
              <p>
                {translate("payment_method")}: {order.paymentMethod}
              </p>
              <p>{translate("duration")}: </p>
              {constructActionsString(order.actions)}
              <p>
                {translate("status")}: {order.status}
              </p>
            </div>
          );
        })}
      </div>
  );

  return (
    <div>
      {renderModal()}
      {fetchData.error && <Alert>{fetchData.error}</Alert>}
      {!fetchData.error && renderContent()}
    </div>
  );
};

export default AdminListWebshopOrders;
